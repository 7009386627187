import React from "react"

const Logo = ({ className, height }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1681.247 848.551"
    >
      <g>
        <g className="text-color">
          <path d="M964.66 403.6l22.22-104.1h-9.5l-16.963 88.6-16.96-88.6h-9.5l22.221 104.1z" />
          <path d="M997.558 299.507h8.624v104.1h-8.624z" />
          <path d="M1054 348.628l-11.115-4.531c-9.5-3.944-11.254-7.455-11.254-19.445 0-6.432 1.314-11.7 4.528-14.914 2.049-2.049 4.97-3.363 9.063-3.363 4.392 0 7.461 1.459 9.507 3.8 3.07 3.508 3.947 7.755 3.947 14.33h8.624c0-9.06-1.168-15.059-6.287-20.175-3.65-3.656-8.914-5.7-15.643-5.7-6.432 0-11.4 1.9-15.207 5.555-4.825 4.528-7.162 11.7-7.162 20.613 0 15.646 3.069 21.64 13.742 26.023l11.109 4.54c8.924 3.654 10.97 8.33 10.97 21.346 0 8.185-.73 12.716-4.093 16.373-2.046 2.194-5.115 3.511-9.946 3.511-4.383 0-7.6-1.317-9.792-3.511-3.215-3.215-4.093-7.459-4.093-15.5h-8.629c0 11.4 1.462 16.524 6.138 21.346 3.8 3.8 9.359 5.554 16.376 5.554 7.461 0 12.722-2.043 16.23-5.554 4.677-4.677 6.433-11.554 6.433-22.515-.003-15.061-2.195-23.245-13.446-27.783z" />
          <path d="M1104.731 404.775a21.818 21.818 0 0015.355-5.993c3.8-3.8 6.139-9.508 6.139-16.815v-82.462h-8.625V382.7c0 8.923-5.709 14.329-12.87 14.329s-13.013-5.406-13.013-14.329v-83.195h-8.624v82.462c0 7.307 2.485 13.016 6.287 16.815a21.8 21.8 0 0015.351 5.993z" />
          <path d="M1151.371 375.1h24.27l5.706 28.508h9.208l-22.805-104.1h-8.039l-23.253 104.1h9.359zm12.139-60.091l10.524 52.336h-20.91z" />
          <path d="M1237.641 395.858H1210V299.5h-8.624v104.1h36.262z" />
          <path d="M1250.948 299.507h8.624v104.1h-8.624z" />
          <path d="M1276.091 307.253h27.633l-29.685 89.627v6.72h38.608v-7.746h-29.537l29.537-89.63V299.5h-36.556z" />
          <path d="M1356.8 375.1l5.709 28.508h9.2l-22.805-104.1h-8.039l-23.253 104.1h9.359l5.555-28.508zm-12.131-60.091l10.524 52.336h-20.907z" />
          <path d="M1404.321 404.774c6.139 0 10.816-1.9 14.333-4.97 4.967-4.531 7.161-11.7 7.3-20.032h-8.624c0 5.261-1.023 10.385-4.1 13.748-2.043 2.192-4.822 3.505-8.915 3.505-4.237 0-7.161-1.461-9.214-3.8-4.24-5.122-4.97-11.115-4.97-41.678s.73-36.544 4.97-41.666c2.053-2.337 4.977-3.8 9.214-3.8 4.093 0 6.872 1.316 8.915 3.508 3.075 3.363 4.1 8.484 4.1 13.745h8.624c-.143-7.891-2.192-14.914-6.72-19.445-3.517-3.508-8.339-5.554-14.917-5.554-6.723 0-11.989 2.336-15.5 6.138-6.577 7.017-7.307 15.207-7.307 47.075s.73 40.07 7.307 47.084c3.515 3.806 8.783 6.142 15.504 6.142z" />
          <path d="M1393.791 428.466h7.755l6.578-19.448h-6.578z" />
          <path d="M1453 285.466c2.195 0 3.953 1.171 6 2.34 2.628 1.462 4.822 2.485 7.892 2.485 2.633 0 5.409-.585 10.385-5.555l-4.247-4.241c-3.069 3.07-4.676 3.512-6.284 3.512-2.191 0-3.8-1.026-5.993-2.34a12.981 12.981 0 00-7.9-2.485c-2.776 0-5.406.584-10.377 5.554l4.093 4.241c3.22-3.218 4.682-3.511 6.431-3.511z" />
          <path d="M1477.428 403.6h9.208l-22.808-104.1h-8.039l-23.253 104.1h9.362l5.555-28.508h24.269zm-28.228-36.259l10.385-52.336 10.525 52.336z" />
          <path d="M1535.62 398.635c6.577-7.014 7.307-15.207 7.307-47.084s-.73-40.061-7.307-47.075c-3.509-3.8-8.924-6.141-15.646-6.141s-11.99 2.339-15.5 6.141c-6.578 7.014-7.307 15.208-7.307 47.075s.729 40.07 7.307 47.084c3.511 3.8 8.778 6.139 15.5 6.139s12.137-2.337 15.646-6.139zm-24.86-5.409c-4.238-5.122-4.971-11.115-4.971-41.675s.733-36.544 4.971-41.669c2.052-2.336 4.976-3.8 9.214-3.8s7.31 1.462 9.356 3.8c4.243 5.125 4.976 11.115 4.976 41.669s-.733 36.553-4.976 41.675c-2.046 2.34-5.119 3.8-9.356 3.8s-7.162-1.46-9.214-3.8z" />
          <path d="M964.8 445.717h-21.343v104.108H964.8c6.871 0 11.993-2.34 15.5-6 6.578-6.872 7.31-14.618 7.31-46.055s-.732-39.184-7.31-46.055c-3.5-3.658-8.626-5.998-15.5-5.998zm9.063 92.7a12.856 12.856 0 01-9.5 3.657h-12.282v-88.6h12.286a12.858 12.858 0 019.5 3.653c4.537 4.97 5.125 10.816 5.125 40.646s-.592 35.673-5.126 40.643z" />
          <path d="M1020.221 445.717l-23.247 104.108h9.353l5.56-28.514h24.27l5.7 28.514h9.211l-22.808-104.108zm-6.578 67.845l10.376-52.345 10.531 52.345z" />
          <path d="M1104 445.717l-23.25 104.108h9.359l5.555-28.514h24.27l5.708 28.514h9.208l-22.808-104.108zm-6.584 67.845l10.383-52.345 10.524 52.345z" />
          <path d="M1188.655 474.525c0-24.275-9.937-28.807-22.517-28.807h-20.468v104.105h8.624v-46.644h13.454l15.059 46.644h9.211l-16.23-48.252c8.626-3.215 12.867-10.963 12.867-27.046zm-22.223 20.9h-12.138V453.47h12.138c10.379 0 13.6 7.6 13.6 21.055s-3.221 20.905-13.6 20.905z" />
          <path d="M1251.824 497.772c0-31.876-.732-40.061-7.316-47.084-3.505-3.8-8.917-6.141-15.643-6.141s-11.987 2.34-15.495 6.141c-6.586 7.023-7.316 15.208-7.316 47.084s.73 40.058 7.316 47.078c3.508 3.805 8.769 6.144 15.495 6.144 5.56 0 10.382-1.61 13.745-4.388l8.188 7.019 4.389-5.264-8.045-6.874c4.243-6.726 4.682-16.379 4.682-43.715zm-11.557 38.015l-7.891-6.58-4.389 5.263 8.191 6.723a12.36 12.36 0 01-7.313 2.05c-4.241 0-7.31-1.32-9.211-3.657-4.383-5.116-4.97-11.109-4.97-41.814s.587-36.7 4.97-41.817c1.9-2.337 4.97-3.654 9.211-3.654s7.458 1.317 9.359 3.654c4.386 5.119 4.97 11.109 4.97 41.817 0 24.999-.441 32.897-2.927 38.015z" />
          <path d="M1303.144 528.915c0 8.917-5.706 14.326-12.867 14.326s-13.013-5.409-13.013-14.326v-83.2h-8.627v82.468c0 7.31 2.485 13.01 6.287 16.809a22.645 22.645 0 0030.705 0c3.8-3.8 6.138-9.5 6.138-16.809v-82.466h-8.623z" />
          <path d="M1330.777 445.716h8.624v104.105h-8.624z" />
          <path d="M1352.708 453.47h17.4v96.353h8.624V453.47h17.4v-7.752h-43.421z" />
          <path d="M1409.437 549.824h36.263v-7.752h-27.633v-40.5h23.833v-7.749h-23.831v-40.352h27.631v-7.752h-36.262z" />
          <path d="M1455.789 453.47h17.4v96.353h8.624V453.47h17.4v-7.752h-43.421z" />
          <path d="M1545.857 528.915c0 8.917-5.708 14.326-12.87 14.326s-13.013-5.409-13.013-14.326v-83.2h-8.624v82.468c0 7.31 2.485 13.01 6.287 16.809a22.645 22.645 0 0030.705 0c3.8-3.8 6.139-9.5 6.139-16.809v-82.466h-8.624z" />
          <path d="M1616.48 474.525c0-24.275-9.947-28.807-22.524-28.807h-20.468v104.105h8.63v-46.644h13.446l15.062 46.644h9.214l-16.231-48.252c8.624-3.215 12.871-10.963 12.871-27.046zm-22.23 20.9h-12.132V453.47h12.132c10.382 0 13.6 7.6 13.6 21.055s-3.218 20.905-13.6 20.905z" />
          <path d="M1658.442 445.717h-8.04l-23.252 104.108h9.359l5.554-28.514h24.27l5.709 28.514h9.2zm-14.623 67.845l10.382-52.345 10.525 52.345z" />
        </g>
        <g>
          <g className="accent-color">
            <path d="M847.878 400.463a63.475 63.475 0 01-84.322 13.955c.089 3.28.143 6.563.143 9.855 0 199.165-161.471 360.638-360.633 360.638S42.431 623.438 42.431 424.273 203.9 63.637 403.066 63.637c163.226 0 301.109 108.477 345.559 257.251a63.481 63.481 0 0183.607-13.525C781.465 129.9 618.047 0 424.282 0 189.96 0 0 189.96 0 424.273s189.96 424.278 424.282 424.278 424.272-189.96 424.272-424.278c0-7.992-.243-15.925-.676-23.81z" />
            <path d="M766.211 360.97a31.817 31.817 0 1031.817-31.823 31.815 31.815 0 00-31.817 31.823z" />
          </g>
          <g className="text-color">
            <path d="M351.162 420.05c19.285-9.463 31.206-26.642 31.206-53.291 0-41.378-30.15-69.779-72.581-69.779-39.973 0-71.179 25.6-73.282 68.027h35.765c1.752-22.1 15.779-35.771 37.517-35.771 20.34 0 36.823 13.327 36.823 38.57 0 22.793-11.928 37.867-37.873 37.867h-5.961v31.212h5.961c28.048 0 41.725 15.786 41.725 41.025 0 26.3-17.532 41.375-40.675 41.375-20.688 0-39.273-11.218-40.676-37.866h-35.764c1.4 48.735 36.467 70.123 76.44 70.123 41.728 0 76.442-24.543 76.442-72.582 0-30.86-13.68-49.438-35.067-58.91z" />
            <path d="M490.366 402.873c-7.714 0-15.427 1.047-22.088 3.852l53.644-107.292H483.7l-56.8 115.715c-10.874 21.737-18.588 41.019-18.588 61.356 0 46.292 30.857 74.687 73.981 74.687s73.64-30.15 73.64-76.08c.003-43.834-29.45-72.238-65.567-72.238zm-8.414 116.412c-22.437 0-37.87-16.832-37.87-43.48 0-27 15.433-43.125 37.87-43.125 23.837 0 38.214 18.582 38.214 43.125 0 26.648-15.776 43.48-38.214 43.48z" />
            <path d="M649.038 297.33c-39.622 0-71.884 27-71.884 71.529v110.807c0 44.53 32.262 71.525 71.884 71.525s72.232-26.991 72.232-71.525V368.859c0-44.528-32.609-71.529-72.232-71.529zM685.5 478.61c0 24.2-13.674 40.675-36.465 40.675s-36.117-16.479-36.117-40.675v-108.7c0-24.193 13.321-40.675 36.117-40.675s36.465 16.483 36.465 40.676z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
