import React from "react"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import sanityConfig from "../../sanity-config"
import imageUrlBuilder from "@sanity/image-url"

const Image = ({
  source,
  className,
  maxWith = 1280,
  maxHeight = 768,
  cover,
  ...props
}) => {
  if (source.asset.fluid) {
    return <GatsbyImage fluid={source.asset.fluid} {...props} />
  }

  if (source.asset._id) {
    const imageAssetId = source.asset._id
    const fluidProps = getFluidGatsbyImage(
      imageAssetId,
      { maxWidth: maxWith, maxHeight: maxHeight },
      sanityConfig.sanity
    )

    return (
      <>
        <GatsbyImage
          fluid={fluidProps}
          className={`
          ${className ? className : ""}
          ${cover ? `object-cover absolute inset-0 w-full h-full` : ""} `}
          {...props}
        />
      </>
    )
  }

  return <img src={source} alt={source.altText} {...props} />
}

export default Image
