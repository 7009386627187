import React from "react"

const Heading = ({ children, level, className }) => {
  return (
    <>
      {level === "1" && <h1 className={`heading ${className}`}>{children}</h1>}
      {level === "2" && <h2 className={`heading ${className}`}>{children}</h2>}
      {level === "3" && <h3 className={`heading ${className}`}>{children}</h3>}
      {level === "4" && <h4 className={`heading ${className}`}>{children}</h4>}
      {level === "5" && <h5 className={`heading ${className}`}>{children}</h5>}
      {level === "6" && <h6 className={`heading ${className}`}>{children}</h6>}
      {level === "xs" && (
        <h6 className={`heading text-base ${className}`}>{children}</h6>
      )}

      {!level && <div className="heading">{children}</div>}
    </>
  )
}

export default Heading
