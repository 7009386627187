import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import startCase from "lodash.startcase"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../helpers/serialize"

const PageTemplate = ({ data, pageContext }) => {
  const { title, description, slug, _rawContent } = data.sanityPage

  const titleFallback = startCase(slug.br.current)

  return (
    <>
      <SEO
        title={title.br ? title.br : titleFallback}
        description={
          description ? description.br : data.sanitySiteSettings.description.br
        }
      />

      {_rawContent && (
        <BlockContent blocks={_rawContent.br} serializers={serializers} />
      )}
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query GET_PAGE($id: String) {
    sanitySiteSettings {
      description {
        br
      }
    }
    sanityPage(id: { eq: $id }) {
      title {
        br
      }
      slug {
        br {
          current
        }
      }
      description {
        br
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`
