import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Image from "../components/image"
import ProjectInfo from "../components/projectInfo"
import ProjectNav from "../components/projectNav"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import startCase from "lodash.startcase"
import serializers from "../helpers/serialize"
import ImageWrapper from "../components/imageWrapper.js"
import CallToAction from "../components/callToAction"

const PortfolioSanity = ({ data, pageContext, location: locale }) => {
  const project = data.project
  // const siteTitle = data.site.siteMetadata.title

  const {
    title,
    publishedAt,
    featuredImage,
    projectInfo,
    _rawBody,
    slug,
    description,
  } = project

  const titleFallback = startCase(slug.br.current)

  const portfolioCta = {
    _key: "66845894e247",
    _type: "callToAction",
    backgroundImage: true,
    colorPicker: "#0F1014",
    content: [
      {
        _key: "b8bc4344dac8",
        _type: "block",
        children: [
          {
            _type: "span",
            marks: [],
            text:
              "Entre em contato e nos explique sobre o seu projeto que planejamos a maquete ideal para a sua necessidade.",
          },
        ],
      },
    ],
    ctaForms: {
      _id: "52e6a29d-e00f-445fcbd2518",
      buttonText: {
        br: "Solicitar Orçamento",
      },
      sucessMessage: {
        br: "Obrigado. Seu download iniciará dentro de poucos segundos.",
      },
      title: {
        br: "Portfolio - Solicitar Orçamento",
      },
      fields: [
        {
          title: {
            br: "Nome",
          },
          type: "text",
        },
        {
          title: {
            br: "Empresa",
          },
          type: "text",
        },
        {
          title: {
            br: "E-mail",
          },
          type: "email",
        },
      ],
    },
    heading: "Faça um orçamento sem compromisso",
    image: featuredImage,
    kicker: "Gostou desta maquete?",
    reverseColumns: true,
  }

  return (
    <>
      <SEO
        title={title.br ? title.br : titleFallback}
        description={
          description ? description.br : data.sanitySiteSettings.description.br
        }
      />

      <article className="post-template mb-24">
        <div className="aspect-ratio-16/9 xl:aspect-ratio-none lg:min-h-screen relative overflow-hidden">
          <div className="image absolute w-full h-full inset-0">
            <div className="bg-gradient-b-primary-25 absolute w-full h-full inset-0 z-20" />
            <ImageWrapper source={featuredImage} cover />
          </div>
        </div>

        <ProjectInfo
          data={projectInfo}
          publishedAt={publishedAt}
          title={title.br}
        />
        <section className="max-w-3xl px-4 mx-auto py-16">
          <BlockContent blocks={_rawBody.br} serializers={serializers} />
        </section>
      </article>
      <ProjectNav data={pageContext} />

      <CallToAction data={portfolioCta} />
    </>
  )
}

export default PortfolioSanity

export const pageQuery = graphql`
  query GET_SANITY_PORTFOLIO($slug: String!) {
    sanitySiteSettings {
      description {
        br
      }
    }
    project: sanityPortfolio(slug: { br: { current: { eq: $slug } } }) {
      title {
        br
      }
      slug {
        br {
          current
        }
      }
      publishedAt(locale: "pt-br", formatString: "MMMM/YY")
      featuredImage {
        asset {
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
      projectInfo {
        client {
          title
        }
        projectLocation {
          title {
            br
          }
        }
        modelType {
          title {
            br
          }
        }
        modelScale {
          title
        }
        modelApplication {
          title {
            br
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`
