import React from "react"
import YouTube from "react-youtube"

import CallToAction from "../components/callToAction"
import Clients from "../components/clients"
import Contact from "../components/contact"
import Features from "../components/features"
import Hero from "../components/hero"
import Image from "../components/image"
import LongText from "../components/longText"
import OtherServices from "../components/otherServices"
import OurProcess from "../components/ourProcess"
import PageLinks from "../components/pageLinks"
import PortfolioPage from "../components/portfolioPage"
import PortfolioSlider from "../components/portfolioSlider"
import Principles from "../components/principles"
import ProjectList from "../components/projectList"
import Section from "../components/section"
import Team from "../components/team"
import Testimonials from "../components/testimonials"
import VideoContent from "../components/videoContent"

export default {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    image: props => {
      return <Image source={props.node} />
    },
    imageGallery: props => {
      return <PortfolioSlider data={props} />
    },
    hero: ({ node }) => {
      return <Hero data={node} />
    },
    heroSlider: ({ node }) => {
      return <Hero data={node} />
    },
    clientSection: ({ node }) => {
      return <Clients data={node} />
    },
    projectList: ({ node }) => {
      return <ProjectList data={node} />
    },
    section: ({ node }) => {
      return <Section data={node} />
    },
    features: ({ node }) => {
      return <Features data={node} />
    },
    testimonials: ({ node }) => {
      return <Testimonials data={node} />
    },
    callToAction: ({ node }) => {
      return <CallToAction data={node} />
    },
    longText: ({ node }) => {
      return <LongText data={node} />
    },
    principles: ({ node }) => {
      return <Principles data={node} />
    },
    ourProcess: ({ node }) => {
      return <OurProcess data={node} />
    },
    team: ({ node }) => {
      return <Team data={node} />
    },
    contact: ({ node }) => {
      return <Contact data={node} />
    },
    otherServices: ({ node }) => {
      return <OtherServices data={node} />
    },
    pageLinks: ({ node }) => {
      return <PageLinks data={node} />
    },
    portfolioPage: ({ node }) => {
      return <PortfolioPage data={node} />
    },
    videoContent: ({ node }) => {
      return <VideoContent data={node} />
    },
    youtube: ({ node }) => {
      let _getVideoIdFromUrl = function (value) {
        var regEx =
          "^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?.com|youtu.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)"
        var matches = value.match(regEx)
        if (matches) {
          return matches[1]
        }
        return false
      }

      const opts = {
        width: "100%",
        height: "100%",
        playerVars: {
          autoplay: 0,
          controls: 0,
          cc_load_policy: 0,
          fs: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
        },
      }

      return (
        <div className="md:-mx-4 lg:-mx-36 xl:-mx-48 mt-8">
          <div className="youtube-wrapper relative min-w-full h-0">
            <YouTube
              className="youtube-playear"
              opts={opts}
              videoId={_getVideoIdFromUrl(node.url)}
            />
          </div>
        </div>
      )
    },
  },
}
