import React from "react"
import Heading from "./heading"
import UniversalLink from "./universalLink"
import ImageWrapper from "./imageWrapper"

const PageLinks = ({ data }) => {
  return (
    <div className="page-links container gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-16">
      {data.pageLink.map((link, index) => {
        const parentPage = link.parentPage.slug.br.current

        return (
          <UniversalLink
            key={index}
            to={`/${parentPage ? parentPage + "/" : ""}${link.slug.br.current}`}
          >
            <div className="bg-primary-300 aspect-ratio-square relative overflow-hidden group">
              <div className="absolute inset-0 w-full h-full p-8 flex items-end z-20 bg-gradient-tr-primary-25">
                <Heading level="xs">{link.title.br}</Heading>
              </div>

              <div className="absolute inset-0 w-full h-full transition-all duration-500 group-hover:opacity-50">
                <ImageWrapper cover source={link.content.br[0].image} />
              </div>
            </div>
          </UniversalLink>
        )
      })}
    </div>
  )
}

export default PageLinks
