import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"

const Section = ({ data }) => {
  const {
    kicker,
    heading,
    content,
    buttons,
    image,
    colorPicker,
    backgroundImage,
    reverseColumns,
  } = data

  return (
    <>
      <Wrapper
        className="section"
        background={backgroundImage && image}
        backgroundColor={colorPicker}
        container
        overlapRight={reverseColumns}
        overlapLeft={!reverseColumns}
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
          {buttons && <Buttons data={buttons.buttons} />}
        </div>

        {!backgroundImage && (
          <div className="image">
            {image && <ImageWrapper source={image} gradientOverlay cover />}
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default Section
