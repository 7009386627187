import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import IconBox from "./iconBox"

const Featues = ({ data }) => {
  const {
    kicker,
    heading,
    content,
    buttons,
    image,
    colorPicker,
    features,
  } = data

  return (
    <>
      <Wrapper
        className="features"
        background={image}
        backgroundColor={colorPicker}
        container
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
          {buttons && <Buttons data={buttons.buttons} />}
        </div>

        <div className="feature-list">
          {features &&
            features.map((feature, index) => (
              <IconBox key={index} data={feature} />
            ))}
        </div>
      </Wrapper>
    </>
  )
}

export default Featues
