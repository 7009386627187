import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Slider from "./slider"

const Hero = ({ data }) => {
  const { kicker, heading, image, content, buttons, heroSlider, _key } = data

  const query = useStaticQuery(graphql`
    {
      allSanityPortfolio(limit: 1, sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            title {
              br
            }
            slug {
              br {
                current
              }
            }
            featuredImage {
              _key
              _type
              asset {
                _id
              }
            }
            _rawBody
          }
        }
      }
    }
  `)

  const portfolioData = query.allSanityPortfolio.edges[0].node
  const portfolioImage = portfolioData.featuredImage
  const projectTitle = portfolioData.title.br
  const defaultText = portfolioData
    ? `Confira nosso último projeto, a maquete para o empreendimento ${portfolioData.title.br}.`
    : false

  const portfolioContent = portfolioData._rawBody.br

  return (
    <>
      <Wrapper
        className="hero"
        overlapLeft
        container
        backgroundBlur
        background={
          image ||
          (heroSlider && heroSlider.gallery[0]) ||
          portfolioImage ||
          false
        }
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && (
            <Heading level="1">
              {"{projectTitle}" === heading ? projectTitle : heading}
            </Heading>
          )}

          {"{projectTitle}" !== heading && content && (
            <Content data={content} />
          )}

          {"{projectTitle}" === heading && (
            <div className="lead">
              <p>
                {portfolioContent &&
                portfolioContent[0].children[0].text &&
                portfolioContent[0].children[0].text
                  ? portfolioContent[0].children[0].text
                  : defaultText}
              </p>
            </div>
          )}

          {buttons && (
            <Buttons
              portfolioLink={`portfolio/${portfolioData.slug.br.current}`}
              data={buttons.buttons}
            />
          )}
        </div>

        <div className="image">
          {!heroSlider && (image || portfolioImage) && (
            <ImageWrapper
              source={image || portfolioImage}
              gradientOverlay
              cover
            />
          )}

          {heroSlider && (
            <Slider
              id={_key}
              className="hero-slider"
              source={heroSlider.gallery}
              autoPlay={{ delay: 5000 }}
            />
          )}
        </div>
      </Wrapper>
    </>
  )
}

export default Hero
