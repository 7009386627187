import React from "react"
import Image from "./image"

const ImageWrapper = ({
  source,
  cover,
  gradientOverlay,
  maxWidth,
  className,
  maxHeight,
}) => {
  return (
    <div
      className={`image-wrapper flex items-center w-full h-full ${
        className ? className : ""
      }`}
    >
      <div className={`overlay ${gradientOverlay && "gradient-overlay"} `}>
        <Image
          source={source}
          cover={cover}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
        />
      </div>
    </div>
  )
}

export default ImageWrapper
