import React from "react"

export const Svgs = require.context("../icons", true, /\.svg$/)

export const Icons = Svgs.keys().reduce((images, path) => {
  const key = path.substring(path.lastIndexOf("/") + 1, path.lastIndexOf("."))
  images[key] = Svgs(path).default
  return images
}, {})

const Icon = ({ id, className }) => {
  const Item = Icons[id]

  return Icons[id] ? <Item className={`icon ${className}`} /> : null
}

export default Icon
