import React from "react"

import Heading from "../components/heading"
import Kicker from "../components/kicker"
import Icon from "../components/icon"

const ProjectInfo = ({ data, publishedAt, title }) => {
  const {
    client,
    modelType,
    modelApplication,
    modelScale,
    projectLocation,
  } = data

  return (
    <div className="meta container bg-primary-500 text-center pt-24 relative z-20 lg:-mt-64 ">
      <header className="space-y-8">
        <Kicker>Maquete {modelType.title.br}</Kicker>
        <Heading level="1">{title}</Heading>

        <div className="flex space-y-16 flex-wrap items-baseline justify-center xl:space-x-16 w-full">
          {client && (
            <IconBox
              icon="drafting-compass"
              heading={client.title}
              kicker={"Cliente"}
              data={[]}
            />
          )}

          {projectLocation && (
            <IconBox
              icon="map-marked-alt"
              heading={projectLocation.title.br}
              kicker={"Localização"}
              data={[]}
            />
          )}

          {modelScale && (
            <IconBox
              icon="ruler-triangle"
              heading={modelScale.title}
              kicker={"Escala"}
              data={[]}
            />
          )}

          {modelApplication && (
            <IconBox
              icon="tasks"
              heading={modelApplication.title.br}
              kicker={"Aplicação"}
              data={[]}
            />
          )}

          {publishedAt && (
            <IconBox
              icon="calendar"
              heading={publishedAt}
              data={[]}
              kicker={"Data"}
            />
          )}
        </div>
      </header>
    </div>
  )
}

export default ProjectInfo

export const IconBox = ({ icon, heading, kicker }) => {
  return (
    <div className="iconbox space-y-6">
      <Icon id={icon} className="mx-auto icon mb-0 icon--inverted" />
      <Heading level="3" className="text-xl capitalize">
        {heading}
      </Heading>
      <Kicker className="text-xxs text-primary-100 ">{kicker}</Kicker>
    </div>
  )
}
