import React from "react"
import Wrapper from "./wrapper"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"

const LongText = ({ data }) => {
  const {
    kicker,
    heading,
    content,
    position,
    image,
    colorPicker,
    backgroundImage,
  } = data

  return (
    <>
      <Wrapper
        className="long-text"
        backgroundColor={colorPicker}
        container
        overlapLeft={"left" === position}
        overlapRight={"right" === position}
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
        </div>

        {!backgroundImage && (
          <div className="image">
            {image && <ImageWrapper source={image} gradientOverlay cover />}
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default LongText
