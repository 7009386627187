import React from "react"
import Slider from "../components/slider"

const PortfolioSlider = ({ data }) => {
  const images = data.node.gallery

  return (
    <>
      {images && (
        <Slider
          id={`_${data.node._key}`}
          className="portfolio-slider md:-mx-4 lg:-mx-36 xl:-mx-48 mt-8"
          source={images}
          autoPlay={{ delay: 5000 }}
          slidesPerView="auto"
          spaceBetween={32}
        />
      )}
    </>
  )
}

export default PortfolioSlider
