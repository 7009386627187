import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Slider from "./slider"

const ProjectList = ({ data }) => {
  const { _key, kicker, heading, content, buttons, colorPicker } = data

  const query = useStaticQuery(bigQuery)
  const portfolioItems = query.allPosts.edges
  const { filter } = data

  const projects = []

  if (filter) {
    const filterType = filter._type
    const filterBy = filter.title.br
    portfolioItems.forEach(project => {
      if (
        project.node.projectInfo[filterType] &&
        project.node.projectInfo[filterType].title.br === filterBy
      ) {
        projects.push(project)
      }
    })
  } else {
    portfolioItems.forEach(project => {
      projects.push(project)
    })
  }

  return (
    <Wrapper
      className="project-list w-full mx-auto"
      overlapLeft
      backgroundColor={colorPicker}
    >
      <div className="content space-y-8">
        <div className="container space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
        </div>
      </div>

      <div className="w-full lg:row-start-2 col-span-12 text-center flex py-8">
        <div className="w-full">
          <Slider
            id={`id_${_key}`}
            className="portfolio-slider"
            slidesPerView="auto"
            source={projects.slice(0, 16)}
          />
        </div>
      </div>

      <div className="w-full lg:row-start-3 col-span-12 text-center flex justify-center ">
        {buttons && <Buttons data={buttons.buttons} />}
      </div>
    </Wrapper>
  )
}

export default ProjectList

export const bigQuery = graphql`
  {
    allPosts: allSanityPortfolio(
      limit: 500
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          _type
          title {
            br
          }
          slug {
            br {
              current
            }
          }
          featuredImage {
            asset {
              _id
            }
          }
          projectInfo {
            modelType {
              title {
                br
              }
            }
            modelApplication {
              title {
                br
              }
            }
          }
        }
      }
    }
  }
`
