import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import serializers from "../helpers/serialize"

const TestPage = ({ data }) => {
  return (
    <>
      {/* <div className="min-h-screen container py-48">
      {data.allSanityPortfolio.edges.map((post, index) => (
        <>
          <h1>{post.node.title}</h1>
          <BlockContent
            key={index}
            blocks={post.node._rawBody}
            serializers={serializers}
          />
        </>
      ))}
    </div> */}

      <div>Hello</div>
    </>
  )
}

export default TestPage

// export const pageQuery = graphql`
//   query GET_TEST {
//     allSanityPortfolio {
//       edges {
//         node {
//           title
//           slug {
//             current
//           }
//           _rawBody
//         }
//       }
//     }
//   }
// `
