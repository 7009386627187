import React, { useEffect } from "react"
import ImageWrapper from "./imageWrapper"
import Image from "./image"
import Swiper from "swiper"
import Heading from "./heading"
import Kicker from "./kicker"
import Content from "./content"
import UniversalLink from "./universalLink"

const Slider = ({
  source,
  params,
  id,
  steps,
  className,
  autoPlay,
  slidesPerView = 1,
  spaceBetween = 0,
}) => {
  if (!params) {
    params = {
      slidesPerView: slidesPerView,
      grabCursor: true,
      spaceBetween: spaceBetween,
      autoplay: autoPlay,
      pagination: {
        el: `.${id}-pagination`,
        type: "bullets",
      },
    }
  }

  useEffect(() => {
    new Swiper(`.${id}-container`, params)
  }, [id, params])

  return (
    <>
      <div className={`${id}-container swiper-container ${className}`}>
        <div className="swiper-wrapper">
          {source.map((item, index) => {
            return (
              <>
                {"image" === item._type && (
                  <div key={index} className="swiper-slide">
                    <ImageWrapper source={item} gradientOverlay cover />
                  </div>
                )}

                {"testimonial" === item._type && (
                  <div key={index} className="swiper-slide">
                    <blockquote>
                      {item.testimonial && <p>{item.testimonial}</p>}

                      <footer className="space-y-1">
                        <Heading level="4" className="text-lg mb-0">
                          {item.name}
                        </Heading>

                        <Kicker className="text-xxs">{item.location}</Kicker>
                        <div className="text-xs">{item.company}</div>
                      </footer>
                    </blockquote>
                  </div>
                )}

                {"processStep" === item._type && (
                  <div key={index} className="swiper-slide py-8">
                    <div className="text-huge font-black text-primary-400 opacity-75 absolute w-full h-full p-0 z-0 transform -translate-y-8 leading-none">
                      {index >= 1 && `0${index}`}
                    </div>
                    <div className="wrapper overlap-left py-8">
                      <div className="content space-y-8">
                        <Kicker>{item.kicker}</Kicker>
                        <Heading level="3">{item.heading}</Heading>
                        <Content data={item.content} />
                      </div>

                      <div className="image">
                        <ImageWrapper
                          source={item.image}
                          gradientOverlay
                          cover
                        />
                      </div>
                    </div>
                  </div>
                )}

                {item.node && "portfolio" === item.node._type && (
                  <div
                    className="swiper-slide w-full md:w-slide md:h-slide p-8 group transition-all duration-700"
                    key={item.node._type + "_" + index}
                  >
                    <UniversalLink
                      to={`/portfolio/${item.node.slug.br.current}`}
                    >
                      <div className="w-full bg-accent h-full relative">
                        <div className="bg-gradient-t-black-50 absolute inset-0 z-20 transition-all duration-700 opacity-75 group-hover:opacity-100" />
                        <Image source={item.node.featuredImage} cover />

                        <div className="absolute inset-0 text-left flex items-end z-20 p-8">
                          <div className="space-y-4 transition-all duration-700 transform group-hover:-translate-y-4">
                            <Kicker className="w-full">
                              {item.node.projectInfo.modelType.title.br}
                            </Kicker>
                            <Heading className="w-full" level="4">
                              {item.node.title.br}
                            </Heading>
                          </div>
                        </div>
                      </div>
                    </UniversalLink>
                  </div>
                )}
              </>
            )
          })}
        </div>
        <div className={`${id}-pagination swiper-pagination`} />
      </div>
    </>
  )
}

export default Slider
