import "../styles/main.css"
import "prismjs/themes/prism.css"

import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"

import Footer from "./footer"
import Header from "./header"
import React from "react"
import { hot } from "react-hot-loader/root"
import BackToTop from "react-back-to-top-button"

import Icon from "../components/icon"

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className="relative">
      <div className="relative z-50">
        <BackToTop
          showOnScrollUp
          showAt={100}
          speed={1500}
          easing="easeInOutQuint"
        >
          <div className="w-16 h-16 bg-accent flex justify-center items-center relative">
            <div className="absolute text-base text-white">
              <Icon id="angle-up" className="icon mb-0 icon--white w-4" />
            </div>
          </div>
        </BackToTop>
      </div>

      <Header title={data.site.siteMetadata.title} />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.main>
      </AnimatePresence>

      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer> */}
      <Footer title={data.site.siteMetadata.title} />
    </div>
  )
}

export default hot(Layout)
