import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Buttons from "./buttons"
import Content from "./content"
import Heading from "./heading"
import Kicker from "./kicker"
import PortfolioFilters from "./portfolioFilters"
import Wrapper from "./wrapper"
import Image from "./image"
import UniversalLink from "./universalLink"

import { kebabCase } from "lodash"

const PortfolioPage = ({ data }) => {
  const defaultState = {
    scale: {
      value: "",
    },
    location: {
      value: "",
    },
    type: {
      value: "",
    },
    project: {
      value: "",
    },
  }
  const [selectedOption, setSelectedOption] = useState(defaultState)
  const { kicker, heading, content, buttons, colorPicker } = data

  const handleChange = (key, newValue) => {
    setSelectedOption(prevState => ({
      ...prevState,
      [key]: {
        value: newValue,
      },
    }))
  }

  const query = useStaticQuery(graphql`
    {
      allSanityPortfolio(
        limit: 1000
        sort: { fields: publishedAt, order: DESC }
      ) {
        edges {
          node {
            _id
            title {
              br
            }
            slug {
              br {
                current
              }
            }
            featuredImage {
              asset {
                _id
              }
            }
            projectInfo {
              modelType {
                title {
                  br
                }
              }
              modelScale {
                title
              }
              modelApplication {
                title {
                  br
                }
              }
              projectLocation {
                title {
                  br
                }
              }
            }
          }
        }
      }
    }
  `)

  const portfolioItems = query.allSanityPortfolio.edges

  return (
    <Wrapper
      className="w-full mx-auto project-list"
      overlapLeft
      container
      backgroundColor={colorPicker}
    >
      <div className="space-y-8 content">
        {kicker && <Kicker>{kicker}</Kicker>}
        {heading && <Heading level="2">{heading}</Heading>}
        {content && <Content data={content} />}
      </div>

      <div className="w-full col-span-12 text-center lg:row-start-2">
        <PortfolioFilters
          currentValue={selectedOption}
          onChange={handleChange}
        />

        <div
          data-filter-scale={selectedOption.scale.value}
          data-filter-location={selectedOption.location.value}
          data-filter-application={selectedOption.project.value}
          data-filter-type={selectedOption.type.value}
          className="grid grid-cols-1 gap-8 text-left portfolio md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        >
          {portfolioItems.map(({ node: item }) => {
            const { projectInfo, _id, slug, featuredImage, title } = item

            const modelScale =
              projectInfo.modelScale && kebabCase(projectInfo.modelScale.title)
            const modelApplication =
              projectInfo.modelApplication &&
              kebabCase(projectInfo.modelApplication.title.br)
            const projectLocation =
              projectInfo.projectLocation &&
              kebabCase(projectInfo.projectLocation.title.br)
            const modelType =
              projectInfo.modelType && kebabCase(projectInfo.modelType.title.br)

            return (
              <div
                data-model-scale={modelScale}
                data-model-type={modelType}
                data-model-application={modelApplication}
                data-model-location={projectLocation}
                key={_id}
                className={`bg-primary-400 focus:
                ${
                  modelScale !== selectedOption.scale.value &&
                  selectedOption.scale.value !== "all" &&
                  selectedOption.scale.value &&
                  "hidden transition-all duration-700 ease-out"
                }

                    ${
                      modelType !== selectedOption.type.value &&
                      selectedOption.type.value !== "all" &&
                      selectedOption.type.value &&
                      "hidden transition-all duration-700 ease-out"
                    }

                    ${
                      modelApplication !== selectedOption.project.value &&
                      selectedOption.project.value !== "all" &&
                      selectedOption.project.value &&
                      "hidden transition-all duration-700 ease-out"
                    }

                    ${
                      projectLocation !== selectedOption.location.value &&
                      selectedOption.location.value !== "all" &&
                      selectedOption.location.value &&
                      "hidden transition-all duration-700 ease-out"
                    }


                `}
              >
                <div className="relative bg-primary-800">
                  <UniversalLink to={`/portfolio/${slug.br.current}`}>
                    <Image
                      source={featuredImage}
                      maxHeight="200"
                      maxWith="200"
                    />
                  </UniversalLink>
                </div>
                <div className="p-8 space-y-1">
                  <Kicker className="text-primary-100">
                    <UniversalLink to={`/portfolio/${slug.br.current}`}>
                      Maquete {projectInfo.modelType.title.br}
                    </UniversalLink>
                  </Kicker>
                  <Heading level="6" className="mb-0">
                    <UniversalLink to={`/portfolio/${slug.br.current}`}>
                      {title.br}
                    </UniversalLink>
                  </Heading>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex justify-center w-full col-span-12 text-center lg:row-start-3">
        {buttons && <Buttons data={buttons.buttons} />}
      </div>
    </Wrapper>
  )
}

export default PortfolioPage
