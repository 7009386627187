import React from "react"
import Wrapper from "./wrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Image from "./image"

const Team = ({ data }) => {
  const { kicker, heading, content, colorPicker, member: members } = data

  // !todo - css classes

  return (
    <>
      <Wrapper className="team" backgroundColor={colorPicker} container>
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
        </div>

        <div className="team-members">
          {members.map((member, index) => {
            return (
              <div key={member._key} className="relative bg-primary-300">
                <div className="bg-accent relative">
                  <Image source={member.image} maxHeight="200" maxWith="200" />
                </div>

                <div className="p-5 space-y-2">
                  <Heading level="xs">{member.name}</Heading>
                  <div className="text-xs font-semibold text-gray-600">
                    {member.position}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Wrapper>
    </>
  )
}

export default Team
