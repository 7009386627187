import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Heading from "./heading"
import Content from "./content"

const OtherServices = ({ data }) => {
  const { colorPicker, title, content, finalContent, services } = data

  return (
    <>
      <Wrapper
        className="other-services"
        backgroundColor={colorPicker}
        container
      >
        <div className="col-span-12 max-w-2xl space-y-8">
          {title && <Heading level="3">{title}</Heading>}
          {content && (
            <div className="lead">
              <Content data={content} />
            </div>
          )}
        </div>

        <div className="service lg:row-start-2 col-span-12 space-y-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-primary-300 grid grid-cols-1 lg:grid-cols-2 gap-8 w-full"
            >
              <ImageWrapper cover source={service.image} />
              <div className="p-12">
                {service.heading && (
                  <Heading level="5">{service.heading}</Heading>
                )}
                {service.content && <Content data={service.content} />}
              </div>
            </div>
          ))}
        </div>

        <div className="lg:row-start-3 col-span-12 space-y-8">
          <div className="max-w-4xl mx-auto lead">
            {finalContent && <Content lead data={finalContent} />}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default OtherServices
