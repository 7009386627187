import React from "react"
import { Link } from "gatsby"
import Icon from "../components/icon"
import Kicker from "../components/kicker"
import Heading from "../components/heading"

const ProjectNav = ({ data }) => {
  const { previous, next, parent } = data

  return (
    <div>
      <nav className="post-nav bg-primary-300 py-8 px-6 ">
        <ul className="flex flex-wrap justify-between items-center">
          <li>
            {previous && (
              <Link to={`${parent}${previous.slug.br.current}`} rel="prev">
                <div className="flex items-center">
                  <Icon
                    id="long-arrow-left"
                    className="mx-auto icon mb-0  icon--main-light"
                  />
                  <div className="ml-4">
                    <Kicker className="text-xxs text-gray-600">
                      Projeto Anterior
                    </Kicker>
                    <Heading level="5" className="mb-0">
                      {previous.title.br}
                    </Heading>
                  </div>
                </div>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <>
                <Link to={`${parent}${next.slug.br.current}`} rel="prev">
                  <div className="flex items-center">
                    <div className="mr-4">
                      <Kicker className="text-xxs text-gray-600">
                        Próximo Projeto
                      </Kicker>
                      <Heading level="5" className="mb-0">
                        {next.title.br}
                      </Heading>
                    </div>
                    <Icon
                      id="long-arrow-right"
                      className="mx-auto icon mb-0  icon--main-light"
                    />
                  </div>
                </Link>
              </>
            )}
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default ProjectNav
