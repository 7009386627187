import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import YouTube from "react-youtube-embed"
import getYouTubeID from "get-youtube-id"

const videoContent = ({ data }) => {
  const {
    kicker,
    heading,
    image,
    content,
    buttons,
    _key,
    url,
    fullWidthVideo,
    reverseColumns,
  } = data

  console.log(data)

  return (
    <Wrapper
      container
      oneThirdRight={reverseColumns}
      oneThirdLeft={!reverseColumns}
    >
      <div
        className={`${
          fullWidthVideo
            ? "row-start-0 row-end-0 col-start-1 col-end-12 text-center"
            : "content"
        }  space-y-8`}
      >
        {kicker && <Kicker>{kicker}</Kicker>}
        {heading && (
          <Heading level="2">
            {"{projectTitle}" === heading ? projectTitle : heading}
          </Heading>
        )}

        {content && <Content data={content} />}
      </div>

      <div
        className={`${
          fullWidthVideo
            ? "row-start-0 row-end-0 col-start-1 col-end-12"
            : "image"
        }  space-y-8`}
      >
        <YouTube id={getYouTubeID(url)} />
      </div>
    </Wrapper>
  )
}

export default videoContent
