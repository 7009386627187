import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Image from "./image"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Slider from "./slider"

const Clients = ({ data }) => {
  const { kicker, heading, content, buttons, logos, colorPicker } = data

  return (
    <>
      <Wrapper
        className="clients"
        backgroundColor={colorPicker}
        container
        oneThirdRight
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
          {buttons && <Buttons data={buttons.buttons} />}
        </div>

        <div className="image">
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 justify-center gap-8 p-8 items-center">
            {logos &&
              logos.gallery.map((logo, index) => {
                return (
                  <div key={index}>
                    <Image source={logo} />
                  </div>
                )
              })}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Clients
