import React from "react"
import Wrapper from "./wrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Slider from "./slider"

const OurProcess = ({ data }) => {
  const {
    kicker,
    heading,
    content,
    processStep: steps,
    colorPicker,
    _key,
  } = data

  return (
    <>
      <Wrapper className="our-process" backgroundColor={colorPicker} container>
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
        </div>

        <div className="process-steps">
          <div className="process-wrapper">
            {steps && (
              <Slider
                id={`id_${_key}`}
                className="process-slider"
                steps
                source={steps}
              />
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default OurProcess
