import React from "react"
import Image from "./image"

const Wrapper = ({
  background,
  backgroundColor,
  backgroundBlur,
  children,
  className,
  container,
  dualColumns,
  oneThirdLeft,
  oneThirdRight,
  overlapLeft,
  overlapRight,
}) => {
  return (
    <section
      className={`main-content-section ${
        backgroundColor
          ? backgroundColor === "#242831"
            ? "bg-primary-300"
            : backgroundColor === "#1D2028"
            ? "bg-primary-400"
            : backgroundColor === "#0F1014"
            ? "bg-primary-600"
            : backgroundColor === "#0A0B0D"
            ? "bg-primary-700"
            : ""
          : ""
      }`}
    >
      <div
        className={`wrapper
        ${container ? `container` : ""}
        ${dualColumns ? `dual-columns` : ""}
        ${oneThirdLeft ? `one-third-left` : ""}
        ${oneThirdRight ? `one-third-right` : ""}
        ${overlapLeft ? `overlap-left` : ""}
        ${overlapRight ? `overlap-right` : ""}
        ${className}`}
      >
        {children}
      </div>
      {background && (
        <div
          className={`background ${backgroundBlur ? "background-blur" : ""}`}
        >
          <Image source={background} cover />
        </div>
      )}
    </section>
  )
}

export default Wrapper
