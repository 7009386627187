import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import Kicker from "./kicker"
import Content from "./content"
import Icon from "./icon"
import Slider from "./slider"

const Testimonials = ({ data }) => {
  const {
    title,
    content,
    buttons,
    image,
    colorPicker,
    testimonials,
    _key,
  } = data

  return (
    <>
      <Wrapper
        className="testimonials"
        background={image}
        backgroundColor={colorPicker}
        container
      >
        <div className="content space-y-8">
          <Icon
            id="comment-alt-smile"
            className="mx-auto icon icon--inverted"
          />
          {title && <Kicker>{title}</Kicker>}
          {content && <Content data={content} />}
          {buttons && <Buttons data={buttons.buttons} />}
        </div>

        <div>
          <Slider
            id={_key}
            className="testimonial-slider"
            source={testimonials}
          />
        </div>
      </Wrapper>
    </>
  )
}

export default Testimonials
