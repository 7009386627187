import React from "react"
import UniversalLink from "./universalLink"

const Buttons = ({ data, portfolioLink }) => {
  return (
    <div className="buttons space-x-8">
      {data.map((button, index) => {
        const parentPage = button.linkTo.parentPage
          ? button.linkTo.parentPage
          : null

        const link = parentPage
          ? `${parentPage.slug.br.current}/${button.linkTo.slug.br.current}`
          : button.linkTo.slug.br.current

        return (
          <UniversalLink
            className="button"
            key={index}
            to={`/${
              button.hash
                ? `${link}#${button.hash}`
                : portfolioLink && 0 === index
                ? portfolioLink
                : link && link
            }`}
          >
            {button.label}
          </UniversalLink>
        )
      })}
    </div>
  )
}

export default Buttons
