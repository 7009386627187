import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Form from "./form"

const CallToAction = ({ data }) => {
  const {
    backgroundImage,
    buttons,
    colorPicker,
    content,
    ctaForms,
    heading,
    image,
    kicker,
    reverseColumns,
  } = data

  return (
    <>
      <Wrapper
        className="call-to-action"
        background={backgroundImage && image}
        backgroundColor={colorPicker}
        container
        oneThirdRight={reverseColumns}
        oneThirdLeft={!reverseColumns}
      >
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="3">{heading}</Heading>}
          {content && <Content data={content} />}

          {ctaForms && <Form data={ctaForms} />}
        </div>

        {!backgroundImage && (
          <div className="image">
            {image && <ImageWrapper source={image} gradientOverlay />}
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default CallToAction
