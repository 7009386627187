import React from "react"
import Wrapper from "./wrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import IconBox from "./iconBox"

const Principles = ({ data }) => {
  const { kicker, heading, content, colorPicker, principle: principles } = data

  return (
    <>
      <Wrapper className="principles" backgroundColor={colorPicker} container>
        <div className="content space-y-8">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="2">{heading}</Heading>}
          {content && <Content data={content} />}
        </div>

        <div className="principle-list">
          {principles.map((principle, index) => {
            return <IconBox principle key={index} data={principle} />
          })}
        </div>
      </Wrapper>
    </>
  )
}

export default Principles
