import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Pivot as Hamburger } from "hamburger-react"
import UniversalLink from "./universalLink"

const Menu = () => {
  // todo query navigation

  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        menuItems {
          title {
            br
          }
          slug {
            br {
              current
            }
          }
        }
      }
    }
  `)

  const menuItems = data.sanitySiteSettings.menuItems

  const isPartiallyActive = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: "menu-item active" } : null

  const regex = /^content\/pages(\/.+).md$/gi

  const [isOpen, setIsOpen] = useState(false)
  // const toggle = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  return (
    <div className={`navigation ${isOpen && "is-open"}`}>
      <div className="overlay"></div>
      <Hamburger toggled={isOpen} toggle={setIsOpen} color="#fff" />

      <nav>
        {menuItems &&
          menuItems.map((item, index) => {
            const link =
              "home" === item.slug.br.current ? "" : item.slug.br.current

            return (
              <UniversalLink
                className="menu-item"
                activeClassName={"active"}
                onClick={close}
                key={index}
                to={`/${link}`}
              >
                {"home" === item.title.br ? "Home" : item.title.br}
              </UniversalLink>
            )
          })}
      </nav>
    </div>
  )
}

export default Menu
