import React from "react"
import Wrapper from "./wrapper"
import Buttons from "./buttons"
import ImageWrapper from "./imageWrapper"
import Kicker from "./kicker"
import Heading from "./heading"
import Content from "./content"
import Form from "./form"
import IconBox from "./iconBox"

const Contact = ({ data }) => {
  const {
    kicker,
    heading,
    content,
    image,
    colorPicker,
    ctaForms,
    contactInfo,
  } = data

  return (
    <>
      <Wrapper
        className="contact"
        background={image}
        backgroundColor={colorPicker}
        container
      >
        <div className="content space-y-8 p-12 bg-primary-600">
          {kicker && <Kicker>{kicker}</Kicker>}
          {heading && <Heading level="1">{heading}</Heading>}
          {content && <Content lead data={content} />}
          {ctaForms && <Form data={ctaForms} />}
        </div>

        <div className="contact-info relative z-20">
          {contactInfo.map((info, index) => {
            const { title } = info

            return (
              <div key={title} className="p-8 bg-primary-400">
                <IconBox data={info} headingLevel="6" />
              </div>
            )
          })}
        </div>
      </Wrapper>
    </>
  )
}

export default Contact
