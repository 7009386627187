import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Kicker from "../components/kicker"
import { kebabCase } from "lodash"

const PortfolioFilters = ({ onChange, currentValue }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityModelType(sort: { fields: title___br, order: ASC }) {
        edges {
          node {
            title {
              br
            }
          }
        }
      }
      allSanityModelScale(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
          }
        }
      }
      allSanityModelApplication(sort: { fields: title___br, order: ASC }) {
        edges {
          node {
            title {
              br
            }
          }
        }
      }
      allSanityProjectLocation(sort: { fields: title___br, order: ASC }) {
        edges {
          node {
            id
            title {
              br
            }
          }
        }
      }
    }
  `)

  const modelScaleSource = data.allSanityModelScale.edges
  const projectLocation = data.allSanityProjectLocation.edges
  const modelType = data.allSanityModelType.edges
  const modelApplication = data.allSanityModelApplication.edges

  const modelScale = []
  const sortedScale = []

  modelScaleSource.forEach(element => {
    sortedScale.push(element.node.title.substring(2))
  })

  sortedScale
    .sort((a, b) => a - b)
    .forEach(element => {
      modelScale.push({ node: { title: { br: "1/" + element } } })
    })

  const handleChange = event => {
    onChange(event.target.id, event.target.value)
  }

  const Select = ({ options, id, placeholder }) => {
    return (
      <div className="relative">
        <select
          className={`${InputClasses}`}
          id={id}
          value={currentValue[id].value}
          onChange={handleChange}
        >
          <option className="hidden" value="">
            {placeholder}
          </option>
          <option value="all">Tudo</option>
          {options.map((option, index) => {
            const value =
              "object" === typeof option.node.title
                ? option.node.title.br
                : option.node.title
            return (
              <option value={kebabCase(value)} key={value + "_" + index}>
                {value}
              </option>
            )
          })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    )
  }

  return (
    <div className="filters grid grid-cols-1 gap-4 lg:grid-cols-5 my-16 content-center items-center">
      <Kicker className="text-center text-white">Filtrar por:</Kicker>

      <Select id="scale" options={modelScale} placeholder="Escala" />
      <Select
        id="location"
        options={projectLocation}
        placeholder="Localização"
      />
      <Select id="type" options={modelType} placeholder="Tipo" />
      <Select id="project" options={modelApplication} placeholder="Aplicação" />
    </div>
  )
}

export default PortfolioFilters

export const InputClasses =
  "shadow appearance-none rounded-none border border-primary-500 bg-primary-700 w-full py-4 px-8 text-gray-500 leading-tight focus:bg-primary-500 focus:text-white focus:outline-none focus:border-primary-400"
