import Logo from "./logo"
import Menu from "./menu"
import React from "react"
import UniversalLink from "./universalLink"

const Header = ({ title }) => {
  return (
    <div className="header-wraper">
      <header className="header container">
        <UniversalLink className="logo" title={title} to="/">
          <Logo className="text-white fill-current" />
        </UniversalLink>

        <Menu />
      </header>
    </div>
  )
}

export default Header
