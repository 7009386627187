import React, { useState } from "react"
import { motion } from "framer-motion"
import { kebabCase, deburr } from "lodash"

const duration = 0.35

const variants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
}

const changeCase = input => {
  return kebabCase(deburr(input))
}

const Form = ({ data }) => {
  const [state, setState] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const formName = changeCase(data.title.br)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setSubmitted(false)
        if (data.pdfDocument) {
          document.location.href = data.pdfDocument.asset.url
        }

        dataLayer.push({
          formName: formName,
          event: "form-success",
        })

        console.log(formName)

        setSubmitted(true)
      })
      .catch(error => alert(error))
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  return (
    <>
      <form
        method="POST"
        name={formName}
        className="form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value={formName} />

        <div className="hidden">
          <label>
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>

        {data.fields.map((field, index) => {
          if ("select" === field.type) {
            return (
              <label
                key={changeCase(field.title.br)}
                htmlFor={changeCase(field.title.br)}
              >
                <span>{field.title.br}</span>
                <div className="relative">
                  <select
                    id={changeCase(field.title.br)}
                    name={changeCase(field.title.br)}
                    onChange={handleChange}
                    defaultValue="Contato"
                  >
                    <option disabled="" className="hidden">
                      Assunto
                    </option>
                    <option>Orçamentos</option>
                    <option>Informações</option>
                    <option>Contato</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </label>
            )
          }

          if ("textarea" === field.type) {
            return (
              <label
                key={changeCase(field.title.br)}
                htmlFor={changeCase(field.title.br)}
              >
                <span>{field.title.br}</span>
                <textarea
                  onChange={handleChange}
                  rows="6"
                  className="resize-none"
                  id={changeCase(field.title.br)}
                  name={changeCase(field.title.br)}
                  type={field.type}
                  placeholder={field.title.br}
                  required
                />
              </label>
            )
          }

          return (
            <label
              key={changeCase(field.title.br)}
              htmlFor={changeCase(field.title.br)}
            >
              <span>{field.title.br}</span>
              <input
                onChange={handleChange}
                id={changeCase(field.title.br)}
                name={changeCase(field.title.br)}
                type={field.type}
                placeholder={field.title.br}
                required
              />
            </label>
          )
        })}

        <div className="flex">
          <button
            className="button w-full py-5 px-8 my-0 flex items-center justify-center focus:outline-none focus:bg-white focus:text-accent"
            type="submit"
          >
            {data.buttonText.br}
          </button>
        </div>
      </form>

      <div className="relative">
        <motion.div
          variants={variants}
          animate={submitted ? "visible" : "hidden"}
          className={`w-full p-8 text-white bg-green-600 mt-8 text-sm`}
        >
          {data.sucessMessage.br}
        </motion.div>
      </div>
    </>
  )
}

export default Form
